.composition-images {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .composition-image {
    &:first-child {
      .move-up {
        display: none;
      }
    }
    &:last-child {
      .move-down {
        display: none;
      }
    }
  }

  .composition--img {

    img {
      width: 100%;
      aspect-ratio: 1.5;
      object-fit: contain;
      background-color: $ultralightgrey;
      mix-blend-mode: darken;
    }
  }

}
