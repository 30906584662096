.show-deleted-contacts-link {
  font-size: 0.7em;
  text-decoration: none;
}

.deleted-contact-badge {
  background: #000;
  color: #fff;
  margin-right: 20px;
  padding: 1px 14px;
  border-radius: 4px;
  font-family: 'Outfit', serif;
}
