.emails-list {

  .state {
    display: inline-block;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    font-weight: 800;
    line-height: 1.25em;

    &.draft { color: $state-draft; border-bottom-color: $state-draft; }
    &.ready { color: $state-ready; border-bottom-color: $state-ready; }
    &.sent { color: $state-sent; border-bottom-color: $state-sent; }
  }

}

.email-preview {
  max-width: 600px;
  margin: 2em auto;

  img {
    max-width: 100%;
    height: auto;
  }
}

.email-form {

  trix-toolbar {
    position: sticky;
    top: 3.25em;
    background: #fff;
    z-index: 1;
  }

}
