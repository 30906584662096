@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import "bootstrap";

@import "~trix";
@import "~select2";
@import "./simple_form-bootstrap/simple_form-bootstrap";
@import "~izitoast/dist/css/iziToast.min";

@import "jquery.typeahead";

// palette
$lapis-color: #172832ff;
$white: #FFFFFF;
$ultralightgrey: #f8f9fa;
$lightgrey: #eaeef2;
$grey: #546475;
$main-active: #23c6c8;

// email states
$state-draft: #95a3b3;
$state-ready: #172832;
$state-sent: #23c6c8;

@import "./app/mixins";
@import "./app/global";
@import './app/pagination';
@import './app/navbar';
@import "./app/sidebar";
@import "./app/devise";
@import "./app/forms";
@import "./app/badges";
@import "./app/tables";
@import "./app/buttons";
@import "./app/emails";
@import "./app/compositions";
@import './app/account';
@import './app/registries';
@import './app/info_requests';
@import "./app/trix";
@import "./app/documents";
@import "./app/external_users";
@import "./app/notes";
@import "./app/footer";
