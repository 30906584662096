header.navbar {
  &.admin-navbar {
    border-bottom: 10px solid #dc3545;
  }

  .btn-icon-rounded {
    border: 0;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 9px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1;
    font-size: 1.5rem;
    &:active, &:hover {
      border: 0;
      background-color: #e2e6ea;
    }
    &.nav-link{
      padding: 9px;
    }
  }
}
