.note-wrapper {
  padding: 1rem 0;
  border-top: 1px solid $lightgrey;

  .header {
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .note-actions {
      display: none;
      float: right;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .note-author {
      font-style: italic;
    }
  }

  .note-content {
    line-height: 1.25;
  }

  &:hover {
    .note-actions {
      display: inline;
    }
  }
}
