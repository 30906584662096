/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sidebar-collapse {
  background: $ultralightgrey;
}

#rightSidebarCollapse {
  padding: 0.6rem 0.7rem 0.4rem 3.7rem;
  font-size: 0.8em;
  cursor: pointer;
  .account{
    display: inline-block;
    opacity:0.6;
    padding-left:3em;
    &:hover{
      opacity:1;
    }
  }
}

#sidebar{
  position: fixed;
  top: 44px;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: $ultralightgrey;
  transition: all 0.3s;
  border-right: 1px solid rgba(52,46,55,0.04);
  color:rgba($black,0.5);
  padding-top: 20px;

  .logo{
    position: absolute;
    bottom: 50px;
    img{
      padding: 20px;
      width: 100%;
      opacity: 20%;
    }
  }
  .sidebar-content {
    height: 100%;
    padding-bottom: 20px;
  }

  &.collapsed {
    margin-left: -250px;
  }
  a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

  a[aria-expanded="true"] {
    background: rgb(234,240,254);
  }

  ul {
    padding: 20px 0;
    &.nested{
      display: none;
      padding: 0;
      li{
        margin-left: 48px;
        list-style: none;
        a{
          width: 192px;
          line-height: 35px;
        }
      }
    }
    .active{
      .nested{
        display: block;
      }
    }
    li {

      .sidebar-header {
        display: block;
        padding: 10px 10px 10px 32px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.33);
      }

      &.section-label {
        margin-left: 2em;
        margin-bottom: 1em;
        font-size: 0.8em;
      }

      a {
        border: 1px solid #fafafa;
        border-radius: 0 25px 25px 0;
        padding: 0 10px 0 32px;
        display: inline-block;
        line-height: 50px;
        vertical-align: top;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: .01785714em;
        font-size: 0.9em;
        font-weight: 500;

        i {
          font-size: 1.4em;
          margin-right: 1.5rem;
          width: 20px;
        }


        &:hover {
          background: $lapis-color;
          border-color:rgba($lapis-color,0.06);
          color:#fff;
        }
      }

      &.active {
        & > a {
          //background: rgb(234,240,254);
          background:rgba(89,121,153,0.09);
        }

        > a {
          color: #000;
        }
      }

      ul {
        padding: 0;

        li {
          a {
            padding-left: 30px;
          }

          &.active {
            > a {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

// START *** RIGHT SIDEBAR
#rightSidebar {
  display: none;
  background: rgba($lapis-color, 0.9);
  ul{
    li{
      a{
        color: #FFF;
        &:hover{
          background: $lapis-color;
        }
      }
    }
  }
  .avatar{
    text-align: center;
    img{
      min-width: 100px;
    }
  }
  &.active {
    display: block;
    box-shadow: 0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;

    #dismiss {
      display: block;
      text-align: right;
      padding: 1em;
      cursor: pointer;
      color: #FFF;
    }
  }
}

#sidebar, #rightSidebar{
  width: 250px;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(138,146,154,.55);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 1031;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.sidebar-overlay.active {
  display: block;
  opacity: 1;
}

// END *** RIGHT SIDEBAR

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.main {
  width: calc(100% - 250px);
  padding: 84px 0;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  &.no-sidebar{
    width: 100%;
  }
  &.collapsed {
    width: 100%;
    padding: 84px 0 0 0;
  }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.collapsed {
    margin-left: 0;
  }
  .main {
    width: 100%;
  }
  .main.collapsed {
    width: calc(100% - 250px);
  }
  #sidebarCollapse span {
    display: none;
  }
}
