fieldset.external_user_document_collections, fieldset.external_user_document_categories {
  legend {
    display: none;
  }
}

.external-user--collections-selector-wrapper:has(#select_all_collections:checked) {
  .external_user_document_collections {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
}

.external-user--categories-selector-wrapper:has(#select_all_categories:checked) {
  .external_user_document_categories {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
}
