/*trix-toolbar .trix-button-group{
    margin:14px 0;
    border:0;

    button.trix-button {
        min-width:50px;
        min-height:30px;
        border-bottom:0;
        color:$black;
        background:$lightgrey;
        &:not(:first-child){
            border-left: 1px solid #fff;
        }
        &:hover, &.trix-active{
            color: $white;
            background:rgba($main-active, 0.3);
        }
    }
}*/


.trix-button--icon-strike, .trix-button--icon-horizontal-rule, .trix-button--icon-quote, .trix-button--icon-code, .trix-button--icon-number-list,
.trix-button--icon-decrease-nesting-level, .trix-button--icon-increase-nesting-level {
  display: none;
  pointer-events: none;
}
