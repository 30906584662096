.pagination-box{
  font-size: 12.8px;
  color: $grey;
  .per-page{
    text-align: right;
    select{
      font-size: 12.8px;
      width: 75px !important;
      margin-left: 8px;
    }
  }
}
